import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import ReactPlayer from "react-player/lazy"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContentWrapper from "../../styles/ContentWrapper"
import { splashScreen } from "../../config"
import { MDXRenderer } from "gatsby-plugin-mdx"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  padding: 100px 25px 0 25px;
  background-color: ${({ theme, color }) => {
    return theme.colors[color]
  }};
  div,
  h3 {
    color: ${({ theme, color }) => {
      if (color === "research") return theme.colors.black
    }};
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px;
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding: 0;
    }
  }
  h2 {
    color: #000000;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 50px;
  margin: ${({ color }) => {
    if (color === "work") return "30px 0"
    else return "200px 0 30px 0"
  }};
  h3 {
    grid-column-end: span 2;
    font-size: 1.75rem;
    line-height: 2rem;
  }
  h6,
  strong {
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-size: 1.125rem;
    line-height: 1.25rem;
    font-weight: 700;
    color: ${({ theme }) => {
      return theme.colors.textSecondary
    }};
    margin: 0;
  }
  p {
    margin: 0;
  }
  .description {
    grid-column-end: span 3;
    font-size: 1.125rem;
    line-height: 1.25rem;
    ul {
      list-style-type: "- ";
    }
  }
  .hero,
  .gallery,
  .video-wrapper {
    grid-column: 1 / span 12;
  }
  .video-wrapper {
    position: relative;
    padding-top: 56.25%;
  }
  .video-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  .hero.gif {
    width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: flex;
    flex-direction: column;
  }
`

const Info = styled.div`
  grid-column-end: span 2;
  p {
    margin-bottom: 20px;
  }
  a {
    color: ${({ theme }) => theme.colors.textSecondary};
    :hover {
      color: ${({ theme, color }) =>
        color === "research" ? theme.colors.black : theme.colors.textPrimary};
    }
  }
`

const ProjectTemplate = ({ data, pageContext }) => {
  const { frontmatter, body } = data.mdx
  const {
    title,
    category,
    type,
    location,
    collaboration,
    tags,
    date,
    status,
    link,
    gallery,
    video,
  } = frontmatter
  const siteTitle = data.site.siteMetadata.title
  const { next } = pageContext
  const color = category.toLowerCase()
  let heroImage
  if (category === "Work") heroImage = gallery.splice(0, 1)[0]

  return (
    <Layout splashScreen={splashScreen} layoutColor={color} nextProject={next}>
      <SEO title={`${siteTitle} | ${title}`} />
      <StyledSection color={color}>
        <StyledContentWrapper>
          <Grid color={color}>
            {category === "Work" &&
              (heroImage.childImageSharp ? (
                <Img fluid={heroImage.childImageSharp.fluid} className="hero" />
              ) : (
                <img src={heroImage.publicURL} alt="" className="hero gif" />
              ))}
            {video && category !== "Work" && (
              <div className="video-wrapper">
                <ReactPlayer
                  className="video-player"
                  url={video}
                  width="100%"
                  height="100%"
                  config={{
                    vimeo: {
                      playerOptions: {
                        portrait: true,
                        byline: true,
                        title: true,
                      },
                    },
                  }}
                />
              </div>
            )}
            <h3>{title}</h3>
            <Info color={color}>
              <h6>Type: </h6>
              <p>{type}</p>
              {location && (
                <>
                  <h6>Location: </h6>
                  <p>{location.join(" / ")}</p>
                </>
              )}
              {collaboration && (
                <>
                  <h6>Collaborators: </h6>
                  <p>{collaboration.join(", ")}</p>
                </>
              )}
              <h6>Keywords: </h6>
              <p>{tags.join(", ")}</p>
              <h6>Date: </h6>
              <p>{date}</p>
              {status && (
                <>
                  <h6>Status: </h6>
                  <p>{status}</p>
                </>
              )}
              {link && <a href={link}>[Link]</a>}
            </Info>
            <Info></Info>
            <MDXRenderer>{body}</MDXRenderer>
            {video && category === "Work" && (
              <div className="video-wrapper">
                <ReactPlayer
                  className="video-player"
                  url={video}
                  width="100%"
                  height="100%"
                  config={{
                    vimeo: {
                      playerOptions: {
                        portrait: true,
                        byline: true,
                        title: true,
                      },
                    },
                  }}
                />
              </div>
            )}
            {gallery &&
              gallery.map((image) =>
                image.childImageSharp ? (
                  <Img
                    fluid={image.childImageSharp.fluid}
                    className="gallery"
                    key={image.id}
                  />
                ) : (
                  <img
                    src={image.publicURL}
                    alt=""
                    className="gallery gif"
                    key={image.id}
                  />
                )
              )}
          </Grid>
        </StyledContentWrapper>
      </StyledSection>
    </Layout>
  )
}

ProjectTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    next: PropTypes.object,
  }),
}

export default ProjectTemplate

export const pageQuery = graphql`
  query ProjectBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      frontmatter {
        title
        category
        tags
        type
        collaboration
        location
        role
        platforms
        date
        status
        link
        video
        gallery {
          id
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
      }
    }
  }
`
